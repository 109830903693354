import { Pipe, PipeTransform } from '@angular/core';
import { IIntAppealShort } from '../models/IIntAppealShort';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { IIntEmployer } from '../models/IIntEmployer';
import { IIntRepresentedby } from '../models/IIntRepresentedby';
import { IIntSubmissionlink } from '../models/IIntSubmissionlink';

@Pipe({
    name: 'arrreppipe',
    standalone: false
})
export class ArrReppipePipe implements PipeTransform {

  transform(value: IIntRepresentedby): string {
    if(value === null || value === undefined)
      return '';

    return  value.repCompanyName !== undefined ? `${value.contactFirstName ?? ''} ${value.contactLastName ?? ''}<div>${value.repCompanyName ?? ''}</div>` : `${value.contactFirstName ?? ''} ${value.contactLastName ?? ''}`;
  }

}



@Pipe({
    name: 'arremployer',
    standalone: false
})
export class ArrEmployerPipe implements PipeTransform{
  transform(value: IIntEmployer[], ...args: unknown[]): string {

    if(value === null || value === undefined)
      return '';

    return value.map(item => {
      return  `${item.employerName}`; }).join("; ");
  }

}


@Pipe({
    name: 'arrsubmisionlinks',
    standalone: false
})
export class ArrSubmissionLinks implements PipeTransform{
  constructor(private _sanitizer: DomSanitizer) { }
    transform(lnks: IIntSubmissionlink[], element: IIntAppealShort, origin: any): SafeHtml {

      //arg[0] -> element //arg[1] -> origin
      let htmltxt = ""
      lnks.forEach((item, index) =>{
        htmltxt +=`<div class='table-row'><span class="cell1"></span><span class="cell2"><a class="lnk-grn" aria-current="page" title="${item.tooltipTxt}" href="/appeal-sub/${element?.appealNumber}/${origin!.substring(0,3).toUpperCase()}/${item.origDocumentType}|${item.respDocumentType}">${item.labelText}</a></span><span class="cell3" ></span></div>`;
      });
      htmltxt +=`<div class='table-row'><span class="cell1"></span><span class="cell2"><a class="lnk-grn" aria-current="page" title="Nothing to submit." href="/appeal-not/${element?.appealNumber}/${origin!.substring(0,3).toUpperCase()}">Confirm that you have nothing to submit.</a></span><span class="cell3" ></span></div>`;
      htmltxt+="";//
      return htmltxt;
    }
}
