import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { AppealHeader, IAppealHeader } from "../../services/models/IAppealHeader";
import { IProfileData } from "../../services/models/IProfileData";

import { Profile001Service } from "../../services/profile/profile001.service";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { GlobalSupport } from '../../services/shared/global-support';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { AuthService } from 'src/app/services/shared/auth.service';
import { IIntEmployersForContact } from 'src/app/services/models/IIntEmployersForContact';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';
import { IIntAppealShort } from 'src/app/services/models/IIntAppealShort';

@Component({
    selector: 'app-representative-landing',
    templateUrl: './representative-landing.component.html',
    styleUrls: ['./representative-landing.component.css'],
    standalone: false
})
export class RepresentativeLandingComponent implements OnInit {
  step = 0;
  _userData: IProfileData | undefined;
  _appealsInProgress : IAppealHeader | undefined;
  _appealsPast : IAppealHeader | undefined;
  _origin: string[] = ['REPRACT','REPRPST','REPR'];
  _employers: IIntEmployersForContact[] | undefined;
  _wcatEmpRoleID: number = 0;
  _orgSelect: UntypedFormControl= new UntypedFormControl();
  _myAppealsOnly: boolean = false;
  _mainfrom: UntypedFormGroup = new UntypedFormGroup({ org: this._orgSelect });

  constructor(private _profsvc: Profile001Service,
              private _applsvc: Appeal001Service,
              private titleService: Title,
              private _activatedRoute: ActivatedRoute,
              private _globalSupport : GlobalSupport,
              private _spinner: NgxSpinnerService,
              private _notifyService : NotificationService,
              private _authSrv: AuthService,
              private _profileSvc: Profile001Service,
              private _wcatParameterService : WcatParameterService) {

                sessionStorage.removeItem('__wcat.001.sss');
                this._userData = this._authSrv.getUserProfile();
                this._globalSupport.addTracking(this._activatedRoute);

                if(this._authSrv._wCATRoleType !== 'RL13'){
                  this._authSrv.redirectToLandings();
                  return;
                }

               }

  ngOnInit(): void {
    this._wcatParameterService.clientWorkerColumnForSorting = 'REP';
    this._userData = this._authSrv.getUserProfile();
    this._spinner.show();
    this._profileSvc.getEmployers().subscribe(response => {
      this._employers = response;
      this._wcatParameterService.employers = this._employers;
      if(this._userData!.claimBase.wCATOrgRoleId === undefined || this._userData!.claimBase.wCATOrgRoleId == '' || this._userData!.claimBase.wCATOrgRoleId == '0' || this._userData!.claimBase.wCATOrgRoleId == null){
        this._authSrv.updateProfileEmployers(response);
        this._userData = this._authSrv.getUserProfile();
      }
      let tempSelOrgId = parseInt(this._userData!.claimBase!.wCATOrgRoleId);
      this._wcatEmpRoleID = tempSelOrgId;
      //this.getLandingAppeals(this._wcatEmpRoleID.toString());
      var idx = 0; //response!.map(function(e) { return e.employerRoleID; }).indexOf(tempSelOrgId);
      if(response != null || response !== undefined)
        this._authSrv.updateEmployers(response, idx);
      //this._orgSelect = new FormControl(tempSelOrgId);
      //this._orgSelect.setValue(this._employers[0].employerRoleID.toString());
      if (this._wcatParameterService.orgSelectedWorker)
      {
        this._orgSelect.setValue(this._wcatParameterService.orgSelectedWorker);
        idx = this._employers.findIndex((itm) => itm.employerRoleID == (parseInt(this._orgSelect.value)));
      }
      else
      {
        this._orgSelect.setValue(this._employers[0].employerRoleID.toString());  
        this._wcatParameterService.orgSelectedWorker = this._employers[0].employerRoleID.toString();
      }

      this.orgSelected();
    });

    this.titleService.setTitle("WCAT Online Service - Representative Landing");
  }


  setStep(index: number) {
    this.step = index;
  }

  orgSelected(): void{
    if(this._employers == null || this._employers === undefined || this._employers.length == 0)
    return;
    this._spinner.show();
    var idx = this._employers!.map(function(e) { return e.employerRoleID; }).indexOf(this._orgSelect.value);
    this._authSrv.updateEmployers(this._employers, idx);
    this._wcatEmpRoleID = this._orgSelect.value;
    this.getLandingAppeals(this._orgSelect.value);
    this._wcatParameterService.orgSelectedWorker = this._orgSelect.value;
  }

  myAppelsChange(event: MatCheckboxChange){
    this._spinner.show();
    this.getLandingAppeals(this._wcatEmpRoleID.toString());
  }

  getLandingAppeals(wCATOrgRoleId: string){
    this._applsvc.getLandingAppeals(wCATOrgRoleId).subscribe(response => {
      var activeAppeals = this._myAppealsOnly? response.activeAppeals!.filter(w => w.wcatContactRoleID.toString() == this._userData?.claimBase.wCATRoleId ) : response.activeAppeals!;
      var pastAppeals = this._myAppealsOnly? response.pastAppeals!.filter(w => w.wcatContactRoleID.toString() == this._userData?.claimBase.wCATRoleId ) : response.pastAppeals!;

      this._appealsInProgress = new AppealHeader(this._authSrv._userProfile!, "In Progress", activeAppeals);
      this._appealsPast = new AppealHeader(this._authSrv._userProfile!, "Past Appeals", pastAppeals);
      
      for(let appealItem of this._appealsInProgress.appeals){
        appealItem.clientName = this.getClient(appealItem);
      }

      for(let appealItemPast of this._appealsPast.appeals){
        appealItemPast.clientName = this.getClient(appealItemPast);
      }

      this._spinner.hide();
    }, error => {
      this._spinner.hide();
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
    });
  }

  getClient(item: IIntAppealShort):string{
    try{
      if(item == null || item === undefined)
      return '';

      let repid  = item.representsRoleID;

      if(item.worker?.workerRoleID == item.representsRoleID){
        return `${item.worker?.workerFirstName} ${item.worker?.workerLastName}`;
      }

      if(item.employer?.employerRoleID == item.representsRoleID){
        return `${item.employer?.employerName}`;
      }
    }catch(e){
      //con
    }
    return '';
  }
}



