import { Component, OnInit } from '@angular/core';
import { SiteData } from 'src/app/services/models/TrackingObjects';
import { GlobalSupport } from '../../../services/shared/global-support';

@Component({
    selector: 'app-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.css'],
    standalone: false
})
export class SubHeaderComponent implements OnInit {
  tracks: SiteData[];


  constructor(private _globalSupport : GlobalSupport){
    this.tracks = this._globalSupport._tracking;
   }

  ngOnInit(): void {

  }

}
