import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { NotificationService } from 'src/app/services/shared/notification.service';

import { UploadFileData } from "../../../services/models/UploadFileData";

@Component({
    selector: 'app-showfile-dialog',
    templateUrl: './showfile-dialog.component.html',
    styleUrls: ['./showfile-dialog.component.css'],
    standalone: false
})
export class ShowfileDialogComponent implements OnInit {

  datasrc: any;
  filetype: string | undefined;
  _dispItem: UploadFileData | undefined;
  public zoom = '100%';

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadFileData,
  private _sanitiazer : DomSanitizer,
  private _dialogRef: MatDialogRef<ShowfileDialogComponent>,
  private _notifyService : NotificationService) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
   }

  ngOnInit(): void {
    this._dispItem = this.data;
    this.showFile(this.data);
  }


  showFile(item: UploadFileData){

    if(item.mimeType.includes('docx') || item.mimeType.includes('doc') || item.mimeType.includes('rtf')){
         setTimeout(() => { this._dialogRef.close();}, 1000);
         return;
    }

    //this.datasrc = item.base64;

    let tmpflnm = item.file!.name.replace(/\.[^/.]+$/, "");
    this.filetype = item.file!.name.split('.').pop()?.toLocaleLowerCase();
    var reader = new FileReader();
    reader.readAsDataURL(item.file!);

    reader.onload = (event) => {
      this.datasrc = event.target!.result;
    };


  }



}
