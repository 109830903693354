import { Component, ElementRef, KeyValueDiffers, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropComponent } from 'ngx-file-drop';
import { MatStepper } from '@angular/material/stepper';
import { Profile001Service } from "../../services/profile/profile001.service";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { SubmissionsService } from "../../services/appeal/submissions.service";
import { IAppealHeader } from "../../services/models/IAppealHeader";
import { IProfileData } from "../../services/models/IProfileData";
import { GlobalSupport } from '../../services/shared/global-support';
import { UploadFileData } from "../../services/models/UploadFileData";
import { ShowfileDialogComponent } from "../../components/shared/dialogs/showfile-dialog.component";
import { NotificationService } from 'src/app/services/shared/notification.service';
import { AuthService } from 'src/app/services/shared/auth.service';
import { INavData } from 'src/app/services/models/INavData';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
    selector: 'app-appeal-upld',
    templateUrl: './appeal-upld.component.html',
    styleUrls: ['./appeal-upld.component.css'],
    standalone: false
})
export class AppealUpldComponent implements OnInit {
  _fstFrmGrp!: UntypedFormGroup;
  _scdFrmGrp!: UntypedFormGroup;
  _isEditable = false;

  @ViewChild(NgxFileDropComponent) _ngxdrop: NgxFileDropComponent | undefined;
  @ViewChild("inputArea") inputArea: ElementRef | undefined;
  _userData: IProfileData | undefined;
  _appealNo : string | undefined;
  _navData : INavData | undefined;
  _origin: string | undefined;
  _processError: boolean = false;

  _files: NgxFileDropEntry[] = [];
  _uploadItems: UploadFileData[] = [];
  _validating: boolean[] = [false,false, false];
  _totalSize: number = 0;
  _uplading: boolean = false;
  _arrayDiffer: any;
  _fileDifferMap = new Map<number, any>();
  _fileMap = new Map<number, UploadFileData>();
  _processed = 0;
  _stepper: MatStepper | undefined;
  _textToFile : string ='';
  _submittedDate: Date = new Date();


  constructor(private titleService: Title,
              private _activatedRoute : ActivatedRoute,
              private _router : Router,
              private _globalSupport : GlobalSupport,
              private _submSrvc: SubmissionsService,
              private _formBuilder: UntypedFormBuilder,
              private kvDiffers: KeyValueDiffers,
              public dialog: MatDialog,
              private _notifyService : NotificationService,
              private _authSrv: AuthService,
              private _spinner: NgxSpinnerService) {
              }


  ngOnInit(): void {
    this._userData = this._authSrv.getUserProfile();
    this._globalSupport.addTracking(this._activatedRoute);
    this._navData = this._globalSupport.getNavData();
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,this._navData?.submissionType, this._navData?.reference, Number(this._userData!.claimBase!.wCATOrgRoleId));
    this._appealNo = this._navData!.appealNo;
    this._origin = this._navData!.origin;
    this._processError = false;
    this.titleService.setTitle("WCAT Online Service - Upload Documents");
    this._fstFrmGrp = this._formBuilder.group({ subDetails01: ['', Validators.required] });
    this._scdFrmGrp = this._formBuilder.group({ subDetails02: ['', Validators.required] });
  }


  public dropped(files: NgxFileDropEntry[]) {
    this._files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
           let tmpCheck = this._totalSize + ((file.size/1000)* 0.0009765625);
           //Check File extension for updating pictures name
          let tmpflnm = file.name.replace(/\.[^/.]+$/, "");
          let flext = file.name.split('.').pop()?.toLocaleLowerCase();
          let isJpg = false;
          let allowedext = ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'rtf'];

          if(tmpCheck >= 10 || this._uploadItems.length >= 10 || !allowedext.includes(flext!))
          {
            this._validating[2] = true;
            files.pop();
            return;
          }else{
            this._validating[2] = false;
          }

          if(flext === 'jpg'){
            isJpg = true;
          }

          this._totalSize = tmpCheck;
          let docCode = `${this._navData?.origDocumentType}|${this._navData?.respDocumentType}|${this._navData?.wcatCasePartRoleID}`;

          let idx = this._uploadItems.findIndex(f=> f.fileName === file.name);
          if(idx === -1 || isJpg){
            let nwItm = new UploadFileData(this._uploadItems.length,'',docCode, file.name,0,'', false,'','',file);
            this._uploadItems.push(nwItm);
            this._fileDifferMap.set(this._uploadItems.length,this.kvDiffers.find(nwItm).create());
            this._fileMap.set(this._uploadItems.length,nwItm);
          }else{
            let nwItm = new UploadFileData(idx,'',docCode, file.name,0, '', false,'','',file);
            this._uploadItems[idx] = nwItm;
            this._fileDifferMap.set(idx,this.kvDiffers.find(nwItm).create());
            this._fileMap.set(idx,nwItm);
          }
        });
      }
    }
  }


  refreshSize(){
    let tmpSize: number = 0;
    this._uploadItems.forEach((itm: UploadFileData)=>{
      tmpSize += ((itm.file!.size/1000)* 0.0009765625);
    });
    this._totalSize = tmpSize;

  }



  ngDoCheck() {
    for (let [key, flDiffer] of this._fileDifferMap) {
      let flChanges = flDiffer.diff(this._fileMap.get(key));
      if (flChanges) {
        flChanges.forEachChangedItem((rcrd: any) => {
          if(rcrd.key === 'processed'){
              this._processed++;
            if(this._processed == this._uploadItems.length && this._uploadItems.length > 0){
              setTimeout(() => {
                this._submittedDate = new Date();
                if(this._processError == false){
                  this._stepper!.next();
                }
              },4000);
            }

          }
        });
      }
    }
  }



  cancelSubmission(){
    //this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,'', '',this._navData?.wcatEmpRoleID);
    //this._router.navigate([this._globalSupport.goHomeData().routePath]);

    this._globalSupport.removeNavEntryVisited("appeal-sub");
    this._router.navigate(['appeal-det']);

  }

  ngOnChanges(changes: SimpleChanges):void{
    for (let propName in changes) {
      if(propName === 'uploadItems'){

      }
    }
  }

  onNext(stepper: MatStepper, frmIndex: number){
    this._stepper = stepper;
    if(frmIndex == 0){
      this._validating[0] = true;
      //if(this.frmFstError('subDetails01', 'required')){
      if(this._fstFrmGrp.controls['subDetails01'].value.trim().length == 0 && this._uploadItems.length == 0){
        this.inputArea!.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      this._stepper.next();
      this._scdFrmGrp.controls['subDetails02'].setValue(this._fstFrmGrp.controls['subDetails01'].value);
    }

  }

  editSubmission(stepper: MatStepper, frmIndex: number){
    this._stepper = stepper;
   let tempVal = this._scdFrmGrp.controls['subDetails02'].value;
   this._stepper.reset();
    this._fstFrmGrp.controls['subDetails01'].setValue(tempVal);
  }

  public frmFstError = (controlname: string, errorname: string) =>{
    //return   this._validating[0] && this._fstFrmGrp.controls[controlname].hasError(errorname);
    return  this._validating[0] && (this._scdFrmGrp.controls['subDetails02'].value.trim().length > 0 || this._uploadItems.length > 0);
  };


  public fileOver(event: any){

  }

  public fileLeave(event: any){

  }


  selectFiles(){
    this._ngxdrop?.openFileSelector();
  }


  receiveOutput(data: UploadFileData[]){
    this._uploadItems = data;
    this.refreshSize();
  }



  uploadFiles(): void {

    this._spinner.show();
    this._uplading = true;
    this._textToFile  = this._scdFrmGrp.controls['subDetails02'].value.trim();
    if(this._scdFrmGrp.controls['subDetails02'].value != null ||  this._scdFrmGrp.controls['subDetails02'].value.trim().length > 10){

       let filesForm = new FormData();
       if(this._textToFile.length > 10){
        var blob = new Blob([this._textToFile], {type: "text/plain"}); //;charset=utf-8
         filesForm.append('txtcnt', blob, "txt_fle.txt");
       }

       if (this._uploadItems) {
        this._uploadItems.sort((a,b)=> a.index > b.index ? 1: -1);
        for (let i = 0; i < this._uploadItems.length; i++) {
          filesForm.append('ofile', this._uploadItems[i].file!);
        }
       }

       if(this._navData?.submissionType == 'SUBMLNK'){
        this._submSrvc.uploadSubmissions(this._navData?.appealCaseID!, this._navData?.respDocumentType!, this._navData?.wcatCasePartRoleID!, filesForm, this._navData!.appealNo!).subscribe(event => {
          switch(event.type){
            case HttpEventType.Sent:
              //request made
              break;
            case HttpEventType.ResponseHeader:
              //response header received
              break;
              case HttpEventType.UploadProgress:
                //upload progress
                for (let i = 0; i < this._uploadItems.length; i++) {
                this._uploadItems[i].uploadProgress = Math.round(100 * event.loaded / event.total!);
                }
              break;
              case HttpEventType.Response:
                //response received
                for (let i = 0; i < this._uploadItems.length; i++) {
                  this._uploadItems[i].message = "File Uploaded Successfully";
                  this._uploadItems[i].processed = true;
                  this._uploadItems[i].transferOk = true;
                }
                this._spinner.hide();
                this._notifyService.showSuccess('Submission Successful', 'Submission Received');
                this._stepper!.next();

              break;
          }
        }
        ,(error: any) =>{
          this._processError = true;
          this._spinner.hide();
          for (let i = 0; i < this._uploadItems.length; i++) {
            this._uploadItems[i].uploadProgress = -1;
            this._uploadItems[i].processed = true;
            this._uploadItems[i].transferOk = false;
            this._uploadItems[i].message = "Transmission error";
          }

          var txtErr = JSON.stringify(error);
          if (txtErr.includes("406")) {
            this._notifyService.showError('', `It is no longer possible to upload a submission. If you still have something to submit or believe you are receiving this message in error, please contact us at 604-664-7800 or 1-800 663-2782 (toll-free in British Columbia). Please provide us with the appeal number when you call.`);
            //setTimeout(() => { this.finish();}, 2000);
            this.finish();
          }else{
            this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
            //setTimeout(() => { this._stepper?.previous();}, 2000);
            this._stepper?.previous();
          }
        },);
       }

       if(this._navData?.submissionType == 'SUBMGNRC'){
      this._submSrvc.genericSubmission(this._navData?.appealCaseID!, this._navData.appealNo!, filesForm).subscribe(event => {
        switch(event.type){
          case HttpEventType.Sent:
            //request made
            break;
          case HttpEventType.ResponseHeader:
            //response header received
            break;
            case HttpEventType.UploadProgress:
              //upload progress
              for (let i = 0; i < this._uploadItems.length; i++) {
              this._uploadItems[i].uploadProgress = Math.round(100 * event.loaded / event.total!);
              }
            break;
            case HttpEventType.Response:
              //response received
              for (let i = 0; i < this._uploadItems.length; i++) {
                this._uploadItems[i].message = "File Uploaded Successfully";
                this._uploadItems[i].processed = true;
                this._uploadItems[i].transferOk = true;
              }
              this._spinner.hide();
              this._notifyService.showSuccess('Submission Successful', 'Submission Received');
              this._stepper!.next();

            break;
        }
      }
      ,(error: any) =>{
        this._processError = true;
        this._spinner.hide();
        for (let i = 0; i < this._uploadItems.length; i++) {
          this._uploadItems[i].uploadProgress = -1;
          this._uploadItems[i].processed = true;
          this._uploadItems[i].transferOk = false;
          this._uploadItems[i].message = "Transmission error";
        }

        var txtErr = JSON.stringify(error);
        if (txtErr.includes("406")) {
          this._notifyService.showError('', `It is no longer possible to upload a submission. If you still have something to submit or believe you are receiving this message in error, please contact us at 604-664-7800 or 1-800 663-2782 (toll-free in British Columbia). Please provide us with the appeal number when you call.`);
          setTimeout(() => { this.finish();}, 2000);
        }else{
          this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
          //setTimeout(() => { this._stepper?.previous();}, 2000);
          this._stepper?.previous();
        }
      });
       }
    }
  }




  printACopy(){
    window.print();
  }

  finish(){
    //this._router.navigate([this._globalSupport.goHomeData().routePath]);
    var lstEntry = this._globalSupport.popLastVisitedEntry();
    this._globalSupport.removeNavEntryVisited("appeal-sub");
    this._router.navigate(['appeal-det']);
  }

  appealDetNewTab(){
    this._globalSupport.removeNavEntryVisited("appeal-sub");
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['appeal-det'])
    );
    window.open(url, '_blank');
  }


  showFile(item: UploadFileData){
    this.dialog.open(ShowfileDialogComponent, { data: item,
      panelClass: 'full-width-dialog', width: '100%', disableClose: true });
  }

  navMakeSub(appealNo: string, origin: string){
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,'', '',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  navMakeNoSub(appealNo: string, origin: string){
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,'', '',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-not']);
  }


}
