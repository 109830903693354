<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="sub-hdr-lft pt-4">
    <h4>Appeal parties</h4>
    <span></span>

  </div>
</div>
<div class="tab-cnt">
  <div id="" class="main-cnt rnd-01 m-3">
    <div class="tbl-container">

      <div *ngFor='let participant of _participants!; let i=index;'>
        <div class="d-flex flex-wrap">
          <div class="lft-col" id="">
            <h5>

                <div class="hdr-01" [ngSwitch]="participant.participantRole">
                  <div *ngSwitchCase="'APP'">APPELLANT  <a class="lnk-grn" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"
                    matTooltip="Person starting the appeal."
                    matTooltipClass="custom-tooltip">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg></a></div>
                  <div *ngSwitchCase="'AREP'">APPELLANT'S REPRESENTATIVE</div>
                  <div *ngSwitchCase="'RSP'">RESPONDENT <a class="lnk-grn" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"
                    matTooltip="Person responding to the appeal."
                    matTooltipClass="custom-tooltip">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg></a></div>
                  <div *ngSwitchCase="'RREP'">RESPONDENT'S REPRESENTATIVE</div>
                  <div *ngSwitchCase="'AUTH'">AUTHORIZED PARTY</div>
                  <div *ngSwitchCase="'WIP'">INTERESTED PARTY</div>
                  <div *ngSwitchCase="'WIPREP'">INTERESTED PARTY'S REPRESENTATIVE</div>
                  <div *ngSwitchCase="'WES'">WES DIVISION</div>
                  <div *ngSwitchCase="'WESDIV'">WorkSafeBC</div>
                  <div *ngSwitchCase="'TPEMP'">THIRD PARTY EMPLOYER</div>
                  <div *ngSwitchCase="'JHSC'">JOINT HEALTH AND SAFETY COMMITTEE</div>
                  <div *ngSwitchCase="'WRKAR'">WORKER ON ASSESSMENT REVIEW</div>
                  <div *ngSwitchCase="'WRKRI'">WORKER REPRESENTATIVE ON INSPECTION REPORT</div>
                  <div *ngSwitchCase="'WRKSC'">WORKER REPRESENTATIVE ON SAFETY COMMITTEE</div>
                  <div *ngSwitchDefault>{{participant.participantRole.toUpperCase()}}</div>
                </div>
              </h5>
            <div class="rgt-spc"><div *ngIf="participant?.companyName">{{participant?.companyName  ?? ''}}</div><div *ngIf="participant?.firstName">{{participant?.firstName ?? ''}} {{participant?.lastName  ?? ''}}</div><div *ngIf="participant?.contactFirstName">{{participant?.contactFirstName ?? ''}} {{participant?.contactLastName  ?? ''}}</div> </div>
          </div>
          <div class="mid-col" id="">
            <h5><div class="hdr-01" *ngIf="participant.participantStatus != null">PARTICIPATING STATUS</div></h5> <!--{{participant.participantStatus.toUpperCase()}}-->
            <div class="rgt-spc">{{participant.participantStatus}}</div>
          </div>
          <div class="rgt-col" id="">
            <h5><div class="hdr-01" *ngIf="participant.representedBy != null && ((participant.representedBy?.contactFirstName ?? '') + (participant.representedBy?.contactLastName ?? '') + (participant.representedBy?.repCompanyName ??'')).length > 0">
              <div class="hdr-01" [ngSwitch]="participant.representedBy?.repRole">
                <div *ngSwitchCase="'APP'">APPELLANT <a class="lnk-grn" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"
                  matTooltip="Person starting the appeal."
                  matTooltipClass="custom-tooltip">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg></a></div>
                <div *ngSwitchCase="'AREP'">APPELLANT'S REPRESENTATIVE</div>
                <div *ngSwitchCase="'RSP'">RESPONDENT <a class="lnk-grn" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"
                  matTooltip="Person responding to the appeal."
                  matTooltipClass="custom-tooltip">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg></a></div>
                <div *ngSwitchCase="'RREP'">RESPONDENT'S REPRESENTATIVE</div>
                <div *ngSwitchCase="'AUTH'">AUTHORIZED PARTY</div>
                <div *ngSwitchCase="'WIP'">INTERESTED PARTY</div>
                <div *ngSwitchCase="'WIPREP'">INTERESTED PARTY'S REPRESENTATIVE</div>
                <div *ngSwitchCase="'WES'">WES DIVISION</div>
                <div *ngSwitchCase="'WESDIV'">WorkSafeBC</div>
                <div *ngSwitchCase="'TPEMP'">THIRD PARTY EMPLOYER</div>
                <div *ngSwitchCase="'JHSC'">JOINT HEALTH AND SAFETY COMMITTEE</div>
                <div *ngSwitchCase="'WRKAR'">WORKER ON ASSESSMENT REVIEW</div>
                <div *ngSwitchCase="'WRKRI'">WORKER REPRESENTATIVE ON INSPECTION REPORT</div>
                <div *ngSwitchCase="'WRKSC'">WORKER REPRESENTATIVE ON SAFETY COMMITTEE</div>
                <div *ngSwitchDefault>{{participant.representedBy.repRole?.toUpperCase()}}</div>
              </div>
            </div></h5>
            <div class="rgt-spc" class="">
              <div *ngIf="participant.representedBy?.repCompanyName">{{ participant.representedBy?.repCompanyName ?? ''}}</div>
              <div *ngIf="participant.representedBy?.contactFirstName">{{ participant.representedBy?.contactFirstName ?? ''}} {{ participant.representedBy.contactLastName ?? ''}}</div>
            </div>
          </div>
        </div>
        <div style="padding-top: 20px;">
         <!-- <hr class="sepLn"/>  -->
        </div>
      </div>



    </div>
  </div>
  </div>

