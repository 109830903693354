import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { IProfileData } from "../../services/models/IProfileData";
import { Profile001Service } from "../../services/profile/profile001.service";
import { GlobalSupport } from '../../services/shared/global-support';
import { AuthService } from 'src/app/services/shared/auth.service';
import { INavData } from 'src/app/services/models/INavData';

@Component({
    selector: 'app-appeal-nthgcnfm',
    templateUrl: './appeal-nthgcnfm.component.html',
    styleUrls: ['./appeal-nthgcnfm.component.css'],
    standalone: false
})
export class AppealNthgcnfmComponent implements OnInit {
  _navData: INavData | undefined;
  _userData: IProfileData | undefined;
  _appealNo : string | undefined;
  _caseId: number | undefined;
  _origin: string | undefined;
  _notSubDate: Date;

  constructor(private _profsvc: Profile001Service,
              private titleService: Title,
              private _activatedRoute : ActivatedRoute,
              private _router : Router,
              private _globalSupport : GlobalSupport,
              private _authSrv: AuthService) {
      this._userData = this._authSrv.getUserProfile();
      this._navData = this._globalSupport.getNavData();
      this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSSNOTCNF',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber, this._navData?.submissionType,this._navData?.reference, Number(this._userData!.claimBase!.wCATOrgRoleId));
      this._appealNo = this._navData!.appealNo;
      this._caseId = this._navData!.appealCaseID;
      this._origin = this._navData!.origin;
      this._globalSupport.addTracking(this._activatedRoute);
      this._notSubDate = new Date();
    }

  ngOnInit(): void {
    this.titleService.setTitle("WCAT Online Service - Nothing to submit Sent");
    this._notSubDate = new Date();
  }


  printACopy(){
    window.print();
  }

  finish(){
   /*  var landing = this._globalSupport.popUpToLandingEntry();
    this._router.navigate([landing?.rootPath]); */
    
    if (this._globalSupport._navData?.lastVisited === 'SUBMSSNOTCNF')
    {
      let appealDet:boolean = false;
      for(let i=0; i<this._globalSupport._tracking.length; i++){
        if (this._globalSupport._tracking[i].routePath === 'appeal-det'){
          appealDet = true;
        }
      }

      if (appealDet) 
        {
          this._globalSupport.removeNavEntryVisited("appeal-notcnfm");
          this._globalSupport.removeNavEntryVisited("appeal-sub");
          this._router.navigate(['appeal-det']);
        }
        else{
              this._globalSupport.removeNavEntryVisited("appeal-not");
              this._globalSupport.removeNavEntryVisited("appeal-sub");
              var lstEntry = this._globalSupport.popLastVisitedEntry();
              this._router.navigate([lstEntry!.routePath!]);
        }
    }
    else{
      this._globalSupport.removeNavEntryVisited("appeal-not");
      var lstEntry = this._globalSupport.popLastVisitedEntry();
      this._router.navigate([lstEntry!.routePath!]);
    }
  }

  cancelSubmission(){
    var lstEntry = this._globalSupport.popLastVisitedEntry();
    this._router.navigate([lstEntry!.routePath!]);
  }
}
