import { Component, OnInit, Input } from '@angular/core';
import { IIntParticipant } from 'src/app/services/models/IIntParticipant';


@Component({
    selector: 'app-appeal-sc-prt',
    templateUrl: './appeal-sc-prt.component.html',
    styleUrls: ['./appeal-sc-prt.component.css'],
    standalone: false
})
export class AppealScPrtComponent implements OnInit {
  @Input() _participants : IIntParticipant[] | undefined;
  @Input() _origin : string | undefined;
  @Input() _appealNo : string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
