import {AfterViewInit, Component, ViewChild, Input,Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { IIntAppealShort } from 'src/app/services/models/IIntAppealShort';
import { GlobalSupport } from 'src/app/services/shared/global-support';
import { IIntSubmissionlink } from 'src/app/services/models/IIntSubmissionlink';
import { INavData } from 'src/app/services/models/INavData';



@Component({
    selector: 'app-appeal-shrt',
    templateUrl: './appeal-shrt.component.html',
    styleUrls: ['./appeal-shrt.component.css'],
    standalone: false
})
export class AppealShrtComponent implements AfterViewInit {
  @Input() appealData : IIntAppealShort[] | undefined;
  @Input() origin : string | undefined;
  @Output() sortRequest: EventEmitter<Sort> = new EventEmitter();
  private _navData: INavData | undefined;
  @ViewChild(MatSort) sort!: MatSort;
  _datasource :any;

  displayedColumns = ['AppealNumber','Status', 'Type', 'Employer','ActionRequired','DueDate'];

  constructor(private _liveAnnouncer: LiveAnnouncer,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _globalSupport : GlobalSupport) {
                this._navData = this._globalSupport.getNavData();
               }



  ngAfterViewInit() {
   
  }

  ngOnChanges(changes: SimpleChanges):void{
    try{

    for (let propName in changes) {
      if(propName === 'appealData'){
        this._datasource = new MatTableDataSource(this.appealData);
        this._datasource.sort = this.sort!;
      }
    }
  }
  catch(e)
   {
    }
  }

  enableAccess(appeal: IIntAppealShort): boolean{
    try{
      if(appeal === undefined || appeal == null)
          return false;

      if(this.origin?.includes('WRK')){
        if(appeal.worker?.participationStatus == null || appeal.worker?.participationStatus === undefined || (appeal.worker != undefined && appeal.worker?.participationStatus == 'NPRT'))
        return false;
        else
        return true;
      }else if(this.origin?.includes('REP')){
        if(appeal.worker != undefined && (appeal.worker?.workerRoleID == appeal.representsRoleID))
        {
          if(appeal?.worker?.participationStatus == 'NPRT')
            return false;
          else
            return true;
        }
        if(appeal.employer.employerRoleID == appeal.representsRoleID)
        {
          if(appeal?.employer?.participationStatus == 'NPRT')
            return false;
          else
            return true;
        }
      }else if(this.origin!.includes('EMPL')){
        if(appeal?.employer?.participationStatus == 'NPRT')
        return false;
        else
        return true;
      }
      }catch(e){
      return false;
    }
  return false;
  }


  announceSortChange(sortState: Sort) {
    this.sortRequest.emit(sortState);
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  navAppealDet(appealNo: string, origin: string, caseId: number){

    this._globalSupport.setNavData(appealNo, origin,origin,'','',0,caseId, '','','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-det']);
  }

  navMakeSub(origin: string, appeal: IIntAppealShort, item: IIntSubmissionlink){

    this._globalSupport.setNavData(appeal.appealNumber, origin!.substring(0,3).toUpperCase(),origin, item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber, 'SUBMLNK','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  navMakeNoSub(origin: string, appeal: IIntAppealShort, item: IIntSubmissionlink){
    this._globalSupport.setNavData(appeal.appealNumber, origin,origin, item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber, 'NOSUBM','', this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-not']);
  }


  getClient(item: IIntAppealShort):string{
    try{
      if(item == null || item === undefined)
      return '';

      let repid  = item.representsRoleID;

      if(item.worker?.workerRoleID == item.representsRoleID){
        return `${item.worker?.workerFirstName} ${item.worker?.workerLastName}`;
      }

      if(item.employer?.employerRoleID == item.representsRoleID){
        return `${item.employer?.employerName}`;
      }
    }catch(e){
      //con
    }
    return '';
  }


}
