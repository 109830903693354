import { Component, Input, ViewChild, OnInit, SimpleChanges } from '@angular/core';
import {PageEvent, MatPaginator } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { switchMap, map } from 'rxjs/operators';
import { IIntAppealShort } from 'src/app/services/models/IIntAppealShort';
import { IAppealHeader } from "../../services/models/IAppealHeader";
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';


@Component({
    selector: 'app-appeal-mngt',
    templateUrl: './appeal-mngt.component.html',
    styleUrls: ['./appeal-mngt.component.css'],
    standalone: false
})
export class AppealMngtComponent implements  OnInit {
  @Input() appealheaderData : IAppealHeader | undefined;
  @Input() sortType : string | undefined;
  @Input() origin : string | undefined;
  @ViewChild(MatPaginator) paginator?:MatPaginator;

  _sortProps = Object();
  length = 0;
  pageSize = 5;
  pageIndex = 0;
  showTotalPages = 3;
  pageSizeOptions = [5,10,20,30];
  showFirstLastButtons = true;
  lowidx = 0;
  highidx = 0;
  paggedappeals : IIntAppealShort[] | undefined;
  sortedAppeals : IIntAppealShort[] | undefined;


  constructor( private _wcatParameterService : WcatParameterService) {
    this.lowidx = 0;
    this.highidx = this.pageSize;
    this.pageSizeOptions = [0];
  }


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges):void{
    try{
      for (let propName in changes) {

        if(propName === 'appealheaderData'){
          this.sortedAppeals = this.appealheaderData?.appeals;
          this.paginator?.firstPage();
          if(this.sortedAppeals?.length! > 5){
            this.pageSize = 5;
            this.highidx = this.pageSize;
            this.pageSizeOptions =[5,10,20,30];
          }else{
            this.pageSize = this.sortedAppeals?.length!;
            this.highidx = this.pageSize;
            this.pageSizeOptions = [this.sortedAppeals?.length!];
          }


            this.setComponentAppeals();
        }
      }
    }
    catch(e)
    {
    }
  }


  sortRequestHandler(sortState: Sort){


    switch(sortState.active){
    case 'AppealNumber':
        if(sortState.direction ==='asc'){
          this.sortedAppeals?.sort((a,b) =>  a.appealNumber.localeCompare(b.appealNumber));
        }else if(sortState.direction ==='desc'){
          this.sortedAppeals?.sort((a,b) =>  b.appealNumber.localeCompare(a.appealNumber));
        }else{
          this.sortedAppeals?.sort((a,b) =>  a.appealNumber.localeCompare(b.appealNumber));
        }
      break;
      case 'Status':
        if(sortState.direction ==='asc'){
          this.sortedAppeals?.sort((a,b) =>  a.appealStatus?.localeCompare(b.appealStatus));
        }else if(sortState.direction ==='desc'){
          this.sortedAppeals?.sort((a,b) =>  b.appealStatus?.localeCompare(a.appealStatus));
        }else{
          this.sortedAppeals?.sort((a,b) =>  a.appealStatus?.localeCompare(b.appealStatus));
        }
      break;
      case 'Type':
        if(sortState.direction ==='asc'){
          this.sortedAppeals?.sort((a,b) =>  a.appealType?.localeCompare(b.appealType));
        }else if(sortState.direction ==='desc'){
          this.sortedAppeals?.sort((a,b) =>  b.appealType?.localeCompare(a.appealType));
        }else{
          this.sortedAppeals?.sort((a,b) =>  a.appealType?.localeCompare(b.appealType));
        }
      break;
      case 'Employer':
        /* if(sortState.direction ==='asc'){
          this.sortedAppeals?.sort((a,b) =>  a?.employer?.employerName?.localeCompare(b?.employer?.employerName) || a?.worker?.workerLastName?.localeCompare(b?.worker?.workerLastName));
        }else if(sortState.direction ==='desc'){
          this.sortedAppeals?.sort((a,b) =>  b?.employer?.employerName?.localeCompare(a?.employer?.employerName) ||  b?.worker?.workerLastName?.localeCompare(a?.worker?.workerLastName));
        }else{
          this.sortedAppeals?.sort((a,b) =>  a?.employer?.employerName?.localeCompare(b?.employer?.employerName) || a?.worker?.workerLastName?.localeCompare(b?.worker?.workerLastName));
        } */

        if (this._wcatParameterService.clientWorkerColumnForSorting === 'REP')
        {
          if(sortState.direction ==='asc'){
            this.sortedAppeals?.sort((a,b) =>  a?.clientName?.localeCompare(b?.clientName) || a?.clientName?.localeCompare(b?.clientName));
          }else if(sortState.direction ==='desc'){
            this.sortedAppeals?.sort((a,b) =>  b?.clientName?.localeCompare(a?.clientName) ||  b?.clientName?.localeCompare(a?.clientName));
          }else{
            this.sortedAppeals?.sort((a,b) =>  a?.clientName?.localeCompare(b?.clientName) || a?.clientName?.localeCompare(b?.clientName));
          }
        } else
        {
          if(sortState.direction ==='asc'){
            this.sortedAppeals?.sort((a,b) =>  a?.employer?.employerName?.localeCompare(b?.employer?.employerName) || a?.worker?.workerLastName?.localeCompare(b?.worker?.workerLastName));
          }else if(sortState.direction ==='desc'){
            this.sortedAppeals?.sort((a,b) =>  b?.employer?.employerName?.localeCompare(a?.employer?.employerName) ||  b?.worker?.workerLastName?.localeCompare(a?.worker?.workerLastName));
          }else{
            this.sortedAppeals?.sort((a,b) =>  a?.employer?.employerName?.localeCompare(b?.employer?.employerName) || a?.worker?.workerLastName?.localeCompare(b?.worker?.workerLastName));
          }
        }
      break;
//      case 'ActionRequired':
//        if(sortState.direction ==='asc'){
//          this.sortedAppeals?.sort((a,b) =>  a.appealProceeding.toString().localeCompare(b.appealProceeding.toString()));
//        }else if(sortState.direction ==='desc'){
//          this.sortedAppeals?.sort((a,b) =>  b.appealProceeding.toString().localeCompare(a.appealProceeding.toString()));
//        }else{
//          this.sortedAppeals?.sort((a,b) => new Date(b.appealDecisionDate).getTime() - new Date(a.appealDecisionDate).getTime());
//        }
//      break;
      case 'DueDate':
        if(this.origin?.includes('PST')){
          //PAST
          if(sortState.direction ==='asc'){
            this.sortedAppeals?.sort((a,b) =>  { return new Date(a.appealDecisionDate).getTime() - new Date(b.appealDecisionDate).getTime() ||  a.appealNumber.localeCompare(b.appealNumber);  });
          }else if(sortState.direction ==='desc'){
            this.sortedAppeals?.sort((a,b) =>  { return new Date(b.appealDecisionDate).getTime() - new Date(a.appealDecisionDate).getTime() ||  b.appealNumber.localeCompare(a.appealNumber); });
          }else{
            this.sortedAppeals?.sort((a,b) =>  { return new Date(a.appealDecisionDate).getTime() - new Date(b.appealDecisionDate).getTime() ||  a.appealNumber.localeCompare(b.appealNumber); });
          }
        }else{
          //ACTIVE
          if(sortState.direction ==='asc'){
            this.sortedAppeals?.sort((a,b) =>  {
              if(a.submissionLinks.length == 0)
                return  -1;
                else
                return new Date(a.submissionLinks[0]?.deadlineDate).getTime() - new Date(b.submissionLinks[0]?.deadlineDate).getTime();

            });
          }else if(sortState.direction ==='desc'){
            this.sortedAppeals?.sort((a,b) =>  {
              if(b.submissionLinks.length == 0)
                return  -1;
                else
                return new Date(b.submissionLinks[0]?.deadlineDate).getTime() - new Date(a.submissionLinks[0]?.deadlineDate).getTime();
            });
          }else{
            this.sortedAppeals?.sort((a,b) =>  {
              if(a.submissionLinks.length == 0)
                return  -1;
                else
                return new Date(a.submissionLinks[0]?.deadlineDate).getTime() - new Date(b.submissionLinks[0]?.deadlineDate).getTime();
            });
          }
        }
      break;
    }
    this.setComponentAppeals();
  }




  setComponentAppeals(): void{

    let newapplArr = new Array();
    if(this.sortedAppeals == null || this.sortedAppeals === undefined || this.sortedAppeals?.length == 0){
      this.paggedappeals = new Array<IIntAppealShort>();
      return;
    }

    for(let i=this.lowidx; i< this.highidx; i++){
      newapplArr.push(this.sortedAppeals![i]);
    }
    this.paggedappeals = newapplArr;

  }



  handlePageEvent(event: PageEvent) {



    this.length = event.length;
    this.pageSize = event.pageSize;

    if( this.pageSize > this.sortedAppeals!.length)
    this.pageSize = this.sortedAppeals!.length;

    this.pageIndex = event.pageIndex;
    this.lowidx = (this.pageIndex * event.pageSize);
    this.highidx = (this.pageIndex * event.pageSize) + this.pageSize;


    if(this.highidx > this.sortedAppeals!.length)
      this.highidx = this.sortedAppeals!.length;

    this.setComponentAppeals();
  }

}
