import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css'],
    standalone: false
})
export class PageNotFoundComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {

  }

}
