import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';


@Component({
    selector: 'app-appeal-sc-dsc',
    templateUrl: './appeal-sc-dsc.component.html',
    styleUrls: ['./appeal-sc-dsc.component.css'],
    standalone: false
})
export class AppealScDscComponent implements OnInit {
  @Input() _appealDet : IIntAppeal | undefined;
  @Input() _origin : string | undefined;
  @Input() _appealNo : string | undefined;

  _reason: string ='';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    try{
      for (let propName in changes) {
        if(propName === '_appealDet'){

          switch(this._appealDet?.suspensionReason){
            case 'Referral for Determination':
              this._reason = 'Suspension for a Referral to the Board for a Determination on a Matter';
              break;
            case 'Pending Decision from Board':
              this._reason = 'Suspension Pending a Board Decision';
              break;
              case 'Pending Decision from RD':
              this._reason = 'Suspension Pending a Review Division Decision';
              break;
              case 'IHP':
                this._reason = 'Suspension for an Independent Health Professional Review';
                break;
              case 'Referral to Chair':
              this._reason = 'Suspension for a Referral to the WCAT Chair Regarding the Lawfulness of a Policy';
              break;
              default:
                this._reason = '';
                break;
          }
        }
      }
    }
    catch(e){
    }
  }

  isActiveAppeal(): boolean{
    var activeStatus = ['Received', 'Registered', 'Accepted', 'With Panel', 'Suspended'];
    return activeStatus.includes(this._appealDet?.appealStatus?.trim()!);
    }
}
