import { Component, OnInit, Input,AfterViewInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { IIntSubmissionlink } from 'src/app/services/models/IIntSubmissionlink';
import { INavData } from 'src/app/services/models/INavData';
import { GlobalSupport } from 'src/app/services/shared/global-support';



@Component({
    selector: 'app-appeal-sc-prc',
    templateUrl: './appeal-sc-prc.component.html',
    styleUrls: ['./appeal-sc-prc.component.css'],
    standalone: false
})
export class AppealScPrcComponent implements OnInit {
  @Input() _appealDet : IIntAppeal | undefined;
  @Input() _origin : string | undefined;
  @Input() _appealNo : string | undefined;
  @Input() _isFuture: boolean = false;
  @Input() _remainders: boolean =  false;
  @Input() _typeOfHearing : string | undefined;
  @Input() _locationText : string | undefined;
  @Input() _typeOfHearingStaticText : string | undefined;
  private _navData: INavData | undefined;
  

  constructor(private _globalSupport : GlobalSupport,
              private _router : Router) {
                this._navData = this._globalSupport.getNavData();
              }
  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
   
  }

  navMakeSub(appeal: IIntAppeal, item: IIntSubmissionlink){

    this._globalSupport.setNavData(appeal.appealNumber, this._origin!.substring(0,3).toUpperCase(),'APPLDET',item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber, 'SUBMLNK','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  navMakeNoSub(appeal: IIntAppeal, item: IIntSubmissionlink){
    this._globalSupport.setNavData(appeal.appealNumber, this._origin!.substring(0,3).toUpperCase(),'APPLDET', item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber,'SUBMNOT','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-not']);
  }

  genericSubmt(appeal: IIntAppeal){
    this._globalSupport.setNavData(appeal.appealNumber, this._origin!.substring(0,3).toUpperCase(),'APPLDET', '', '', 0, appeal.appealCaseID, appeal.claimNumber,'SUBMGNRC','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }


  ngOnChanges(changes: SimpleChanges){


  }


}
