import { AfterViewInit, Component, OnInit, SimpleChanges } from '@angular/core';
import { IProfileData } from 'src/app/services/models/IProfileData';
import { AuthService } from 'src/app/services/shared/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: false
})
export class HeaderComponent implements AfterViewInit  {
  _userData: IProfileData | undefined;
  _roleType: string | undefined;

  constructor(private _authSrv: AuthService) {
               // this._userData = this._authSrv.getUserProfile();

  }
  ngAfterViewInit(): void {

  }


  ngOnChanges(changes: SimpleChanges){


  }

  menuOpened(){
    this._roleType = this._authSrv._wCATRoleType;
  }

  ngOnInit(): void {

  }

  logOut(){
    this._authSrv.logoutFlow();
  }

  updPass(){
    this._authSrv.resetPassFLow();
  }
  updEmail(){
    this._authSrv.updateEmailFlow();
  }
  addRole(){
    this._authSrv.addRoleFlow();
  }
  contactUs(){
    window.open('https://www.wcat.bc.ca/home/contact-wcat/','_blank');
  }
  gotoHelp(){
    window.open('https://www.wcat.bc.ca/home/resources/wcat-online-services/#ask-for-help','_blank');
  }

  addOrg(){
    this._authSrv.addOrgFlow();
  }
  AddRepFirm(){
    this._authSrv.addOrgFlow();
  }
}
