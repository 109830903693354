import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IIntAppealShort } from 'src/app/services/models/IIntAppealShort';
import { IIntJoinedappeals } from 'src/app/services/models/IIntJoinedappeals';
import { INavData } from 'src/app/services/models/INavData';
import { GlobalSupport } from 'src/app/services/shared/global-support';


@Component({
    selector: 'app-appeal-sc-jnd',
    templateUrl: './appeal-sc-jnd.component.html',
    styleUrls: ['./appeal-sc-jnd.component.css'],
    standalone: false
})
export class AppealScJndComponent implements OnInit {
  @Input() _joinedAppeals : IIntJoinedappeals[] | undefined;
  @Input() _origin : string | undefined;
  @Input() _appealNo : string | undefined;
  private _navData: INavData | undefined;

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _globalSupport : GlobalSupport) {
      this._navData = this._globalSupport.getNavData();
     }

  ngOnInit(): void {
  }



  navAppealDet(appealNo: string, caseId: number){
  //var origin: string = this._navData!.origin!.substring(0,3).toUpperCase();

    this._globalSupport.setNavData(appealNo, this._origin,this._origin,'','',0,caseId, '','','',this._navData?.wcatEmpRoleID);
    if(this._router.url.includes('appeal-det')){
      this._router.navigate(['appeal-srch']);
     }else{
       this._router.navigate(['appeal-det']);
     }
  }

  enableAccess(appeal: IIntAppealShort): boolean{
    try{
      if(appeal === undefined || appeal == null)
          return false;

      if(this._origin?.includes('WRK')){
        if(appeal.worker?.participationStatus == null || appeal.worker?.participationStatus === undefined || (appeal.worker != undefined && appeal.worker?.participationStatus == 'NPRT'))
        return false;
        else
        return true;
      }else if(this._origin?.includes('REP')){
        if(appeal.worker != undefined && (appeal.worker?.workerRoleID == appeal.representsRoleID))
        {
          if(appeal?.worker?.participationStatus == 'NPRT')
            return false;
          else
            return true;
        }
        if(appeal.employer.employerRoleID == appeal.representsRoleID)
        {
          if(appeal?.employer?.participationStatus == 'NPRT')
            return false;
          else
            return true;
        }
      }else if(this._origin!.includes('EMPL')){
        if(appeal?.employer?.participationStatus == 'NPRT')
        return false;
        else
        return true;
      }
      }catch(e){
      return false;
    }
  return false;
  }


}
