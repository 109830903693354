import { Pipe, PipeTransform } from '@angular/core';
import { appealJoinType, appealParticipationType, documentType } from '../../services/models/enums';



@Pipe({
    name: 'enumjoinpipe',
    standalone: false
})
export class EnumJoinPipe implements PipeTransform {

  transform(value: appealJoinType, ...args: unknown[]): string {
    return appealJoinType[value];
  }

}


@Pipe({
    name: 'enumpartpipe',
    standalone: false
})
export class EnumpartPipe implements PipeTransform {

  transform(value: appealParticipationType, ...args: unknown[]): string {
    return appealParticipationType[value].toUpperCase();
  }

}

@Pipe({
    name: 'enumdocpipe',
    standalone: false
})
export class EnumdocPipe implements PipeTransform {

  transform(value: documentType, ...args: unknown[]): string {
    return documentType[value];
  }

}
