import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Profile001Service } from "../../services/profile/profile001.service";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { AppealHeader, IAppealHeader } from "../../services/models/IAppealHeader";
import { IProfileData } from "../../services/models/IProfileData";
import { IViewData } from "../../services/models/IViewData";
import { GlobalSupport } from '../../services/shared/global-support';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { AuthService } from 'src/app/services/shared/auth.service';
import { IIntEmployersForContact } from 'src/app/services/models/IIntEmployersForContact';
import { IntAppealShort } from 'src/app/services/models/IIntAppealShort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { findIndex } from 'rxjs';
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';
import { AppInsightService } from 'src/app/services/shared/app-insight.service';


@Component({
    selector: 'app-employer-landing',
    templateUrl: './employer-landing.component.html',
    styleUrls: ['./employer-landing.component.css'],
    standalone: false
})
export class EmployerLandingComponent implements OnInit {
  step = 0;
  _userData: IProfileData | undefined;
  _appealsInProgress : IAppealHeader | undefined;
  _appealsInDraft : IAppealHeader | undefined;
  _appealsPast : IAppealHeader | undefined;
  _origin: string[] = ['EMPLACT','EMPLPST','EMPL'];
  _employers: IIntEmployersForContact[] | undefined;
  _wcatEmpRoleID: number = 0;
  _orgSelect: UntypedFormControl= new UntypedFormControl();
  _mainfrom: UntypedFormGroup = new UntypedFormGroup({ org: this._orgSelect });
  _sortType?: string;
  _myAppealsOnly: boolean = false;

  constructor(private _profsvc: Profile001Service,
              private _applsvc: Appeal001Service,
              private titleService: Title,
              private _activatedRoute: ActivatedRoute,
              private _globalSupport : GlobalSupport,
              private _spinner: NgxSpinnerService,
              private _notifyService : NotificationService,
              private _authSrv: AuthService,
              private _profileSvc: Profile001Service,
              private _wcatParameterService : WcatParameterService,
              private appInsightService: AppInsightService) {


                sessionStorage.removeItem('__wcat.001.sss');
                this._userData = this._authSrv.getUserProfile();
                this._globalSupport.addTracking(this._activatedRoute);
                if(this._authSrv._wCATRoleType !== 'RL2'){
                  this._authSrv.redirectToLandings();
                  return;
                }
               }

  ngOnInit(): void {
    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing OnInit started'});
    this._userData = this._authSrv.getUserProfile();

    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing wCATOrgRoleId ' + this._userData!.claimBase.wCATOrgRoleId});
    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing wCATRoleId ' + this._userData!.claimBase.wCATRoleId});

    this._spinner.show();
    this._profileSvc.getEmployers().subscribe(response => {
      this._employers = response;
      if(this._userData!.claimBase.wCATOrgRoleId === undefined || this._userData!.claimBase.wCATOrgRoleId == '' || this._userData!.claimBase.wCATOrgRoleId == '0' || this._userData!.claimBase.wCATOrgRoleId == null){
        this._authSrv.updateProfileEmployers(response);
        this._userData = this._authSrv.getUserProfile();
      }
      this.appInsightService._appInsights.trackTrace({ message: 'Employer Landing getEmployers completed' });
      let tempSelOrgId : number =  parseInt(this._userData!.claimBase!.wCATOrgRoleId);
      this._wcatEmpRoleID = tempSelOrgId;
      //this.getLandingAppeals(this._wcatEmpRoleID.toString());
      var idx = 0; //response.findIndex((itm) => itm.employerRoleID == tempSelOrgId);

      //this._orgSelect.setValue(this._wcatEmpRoleID.toString());   // = new FormControl(tempSelOrgId);

      if (this._wcatParameterService.orgSelectedEmployer)
      {
        this._orgSelect.setValue(this._wcatParameterService.orgSelectedEmployer);
        idx = this._employers.findIndex((itm) => itm.employerRoleID == (parseInt(this._orgSelect.value)));
      }
      else
      {
        this._orgSelect.setValue(this._employers[0].employerRoleID.toString());  
        this._wcatParameterService.orgSelectedEmployer = this._employers[0].employerRoleID.toString();
      }

      //this._orgSelect.setValue(this._employers[0].employerRoleID.toString());
      this.orgSelected();

      if((response != null || response !== undefined)&& idx != -1)
        this._authSrv.updateEmployers(response, idx);
    },
      error => {
      this._spinner.hide();
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
    });
    this.titleService.setTitle("WCAT Online Service - Employer Landing");
    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing OnInit finished'});
  }

  setStep(index: number) {
    this.step = index;
  }
  selectionChanged(item: any) {
    this._sortType =item.value;
  }

  orgSelected(): void{
    this.appInsightService._appInsights.trackTrace({message: 'Employer landing orgSelected started'});
    if(this._employers == null || this._employers === undefined || this._employers.length == 0)
    return;
    this._spinner.show();
    var idx = this._employers.findIndex((itm) => itm.employerRoleID == (parseInt(this._orgSelect.value)));
    this.appInsightService._appInsights.trackTrace({message: 'Employer landing orgSelected started - calling updateEmployers'});
    this._authSrv.updateEmployers(this._employers, idx);
    this._wcatEmpRoleID = this._orgSelect.value;
    this.appInsightService._appInsights.trackTrace({message: 'Employer landing orgSelected started - calling getLandingAppeals'});
    this.getLandingAppeals(this._orgSelect.value);
    this._wcatParameterService.orgSelectedEmployer = this._orgSelect.value;
    this.appInsightService._appInsights.trackTrace({message: 'Employer landing orgSelected finished'});
  }

  myAppelsChange(event: MatCheckboxChange){
    this._spinner.show();
    this.getLandingAppeals(this._wcatEmpRoleID.toString());

  }



  getLandingAppeals(wCATOrgRoleId: string){
    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing getLandingAppeals started'});
    this._applsvc.getLandingAppeals(wCATOrgRoleId).subscribe(response => {

      var activeAppeals = this._myAppealsOnly? response.activeAppeals!.filter(w => w.wcatContactRoleID.toString() == this._userData?.claimBase.wCATRoleId ) : response.activeAppeals!;
      var pastAppeals = this._myAppealsOnly? response.pastAppeals!.filter(w => w.wcatContactRoleID.toString() == this._userData?.claimBase.wCATRoleId ) : response.pastAppeals!;

      this._appealsInProgress = new AppealHeader(this._authSrv._userProfile!, "In Progress",activeAppeals);
      this._appealsPast = new AppealHeader(this._authSrv._userProfile!, "Past Appeals", pastAppeals);
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
    });
    this.appInsightService._appInsights.trackTrace({message: 'Employer Landing getLandingAppeals finished'});
  }
}
